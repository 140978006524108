@use 'mastodon/functions';
@use 'mastodon/mixins';
@use 'mastodon/variables';
@use 'mastodon/css_variables';
@use 'fonts/roboto';
@use 'fonts/roboto-mono';

@use 'mastodon/reset';
@use 'mastodon/basics';
@use 'mastodon/branding';
@use 'mastodon/containers';
@use 'mastodon/lists';
@use 'mastodon/widgets';
@use 'mastodon/forms';
@use 'mastodon/accounts';
@use 'mastodon/components';
@use 'mastodon/polls';
@use 'mastodon/modal';
@use 'mastodon/emoji_picker';
@use 'mastodon/annual_reports';
@use 'mastodon/about';
@use 'mastodon/tables';
@use 'mastodon/admin';
@use 'mastodon/dashboard';
@use 'mastodon/rtl';
@use 'mastodon/accessibility';
@use 'mastodon/rich_text';
div.column {
    flex-grow: 1;
}